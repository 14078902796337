export const reviews = [
    {
        content:
            "you truly amazing, it is like a god dam mona lisa quality of work and you really knocked it out of the park. <333 NFSADKLAfkAJSFNSAKNJBS AMAZING BRO you are for sure the best artist on ROBLOX and well worth the money",
        date: "10/12/2023",
    },
    {
        content:
            "Great job on the thumbnail bro everyone thinks its AI because of how good it is, said hazem",
        date: "27/11/2023",
    },
    {
        content:
            "After much searching, I found the perfect candidate for GFX: Star. His exceptional portfolio, skills, and communication made him the best artist I've commissioned. The outcome surpassed my expectations. Highly recommended. 11/10.            ",
        date: "22/7/2023",
    },
    {
        content:
            "Amazing outcome of the gfx, helped me brainstorm ideas and overall communication was very great. Would highly recommend working with star!",
        date: "27/4/2023",
    },
    {
        content:
            "Very good job, very fast at completing the job and the communication was wonderful. Very professional 👍",
        date: "23/4/2023",
    },
    {
        content:
            "Fantastic work, very fast and communication was top notch.",
        date: "18/4/2023",
    },
    {
        content:
            "Excellent work, Incredibly fast and communicative. Delivered the product on time. Would Highly Recommend :)",
        date: "8/4/2023",
    },
    {
        content:
            "Amazing work, exceeded my expectations, always follows up with you 👍 Worth every penny.",
        date: "7/4/2023",
    },
    {
        content:
            "One of the best! Star is extremely talented, professional, and quick at every stage of the production process. I've ordered from him multiple times now and he's always delivered top tier work. 10/10",
        date: "1/4/2023",
    },
    {
        content:
            "Worked so well. Super creative, was delivered within the timeframe he said they would be…. Results were incredible. I’ve been following his work for about 2 years and have always wanted to hire him- I just never had a reason to. Feels really good to finally have something personalized from him for my game.",
        date: "22/3/2023",
    },
    {
        content:
            "Completed my order in less than a day, amazing service and very professional. 10/10, Would highly recommend",
        date: "15/3/2023",
    },
    {
        content:
            "Absolutely amazing service ! Fast and reliadble and very easy to work with. He is very professional and good at communicating with you. Highly recommend!",
        date: "14/3/2023",
    },
    {
        content:
            "Excellent service! Didn’t waste any time working with me to figure out my vision for the GFX, consistently talked me through the process and made sure I enjoyed the progress, and is very friendly! 10/10 would recommend to order from Star.",
        date: "9/3/2023",
    },

];
